import React, { createContext, useContext, useState } from 'react';
import { app } from '../api/feathers';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState({});
  const [token, setToken] = useState(null);
  const [isValidated, setIsValidated] = useState(false);

  async function login(email, password) {
    app.authenticate({
      strategy: 'local',
      email,
      password,
    })
      .then((response) => {
        localStorage.setItem('token', response.accessToken);
        setToken(response.accessToken);
        setUser(response.user);
        setIsValidated(true);
      })
      .catch((error) => {
        console.log(error);
        setIsValidated(true);
      });
  }

  async function validateLoginToken(token) {
    console.log('validando token validateLoginToken', token)
    app.authenticate({
      strategy: 'jwt',
      accessToken: token,
    })
      .then((response) => {
        localStorage.setItem('token', response.accessToken);
        setToken(response.accessToken);
        setUser(response.user);
        setIsValidated(true);
      })
      .catch((error) => {
        console.log(error);
        setIsValidated(true);
      });
  }

  async function validateToken() {
    const { token } = app.authentication.options.storage;
    console.log('validando token validateToken', token)
    app.authenticate({
      strategy: 'jwt',
      accessToken: token,
    })
      .then((response) => {
        setToken(response.accessToken);
        setUser(response.user);
        setIsValidated(true);
      })
      .catch((error) => {
        console.log(error);
        setIsValidated(true);
      });
  }

  async function logout() {
    localStorage.removeItem('feathers-jwt');
    localStorage.removeItem('token');
    setToken(null);
    app.logout();
  }

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        user,
        token,
        isValidated,
        validateLoginToken,
        logout,
        validateToken,
      }}
    >
      { children }
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
