import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';

const PlacesContext = createContext();
const initialPlace = {
  id: null,
  pointId: null,
  contractId: null,
  userName: null,
  name: null,
  email: null,
  instagram: null,
  type: null,
  urlMenu: null,
  urlImage: null,
  lat: null,
  lng: null,
  cnpj: null,
  address: null,
  addressDistrict: null,
  addressCity: null,
  addressCep: null,
  addressAdditional: null,
  addressNeighborhood: null,
  pix: null,
  haveQrcode: false,
  placeTypeId: null,
  legalName: null,
  bankName: null,
  bankAgency: null,
  bankAccount: null,
  hashAuth: null,
};

export function PlacesProvider({ children }) {
  const [places, setPlaces] = useState([]);
  const [placeTypes, setPlaceTypes] = useState([]);
  const [editPlace, setEditPlace] = useState(initialPlace);

  function getPlace(placeId) {
    services.places.get(placeId).then((response) => {
      console.log('getPlace', response);
      setPlaces([response]);
    });
  }

  function getEditPlace(placeId) {
    services.places.get(placeId).then((response) => {
      console.log('getPlace', response);
      setEditPlace(response);
    });
  }

  function findPlaces(query = {}) {
    services.places.find(query).then((response) => {
      console.log('findPlaces', response);
      setPlaces(response.data);
    });
  }

  async function createPlaces(place) {
    return services.places.create(place).then(() => {
      findPlaces();
    });
  }

  async function patchPlaces(id, place) {
    return services.places.patch(id, place).then(() => {
      setEditPlace(initialPlace);
      findPlaces();
    });
  }

  async function removePlaces(placeId) {
    return services.places.remove(placeId).then(() => {
      findPlaces();
    });
  }
  return (
    <PlacesContext.Provider
      value={{
        places,
        editPlace,
        setEditPlace,
        getEditPlace,
        findPlaces,
        createPlaces,
        patchPlaces,
        getPlace,
        removePlaces,
      }}
    >
      {children}
    </PlacesContext.Provider>
  );
}

export function usePlaces() {
  const context = useContext(PlacesContext);

  return context;
}
