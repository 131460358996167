import React, { useCallback, useEffect, useRef, useState } from 'react';

import Modal from '@mui/material/Modal'
import { useParams } from 'react-router-dom';
import { Grid, Avatar, FormControl, Button, InputLabel, Select, OutlinedInput, MenuItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import QrCode2Outlined from '@mui/icons-material/QrCode2Outlined';
import { useSnackbar } from 'notistack';
import { usePlaces } from '../contexts/usePlaces';
import { usePlaceZones } from '../contexts/usePlaceZones';
import { useQrcodeSizes } from '../contexts/useQrcodeSizes';
import { usePoints } from '../contexts/usePoints';
import { useAuth } from '../contexts/useAuth';
import QrCodeReader, { QRCode } from 'react-qrcode-reader';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { validateTime } from '@mui/x-date-pickers/internals/hooks/validation/useTimeValidation';

interface PlaceTypeInterface {
  id: number | null;
  name: string | null;
}

interface PlaceInterface {
  id: number | null;
  name: string | null;
  urlImage?: any | null;
  email: string | null;
  instagram: string | null;
  type: string | null;
  urlMenu: string | null;
  lat: string | null;
  lng: string | null;
  cnpj: string | null;
  legalName: string | null;
  bankName: string | null;
  bankAgency: string | null;
  bankAccount: string | null;
  address: string | null;
  addressState: string | null;
  addressCity: string | null;
  addressNeighborhood: string | null;
  addressCep: string | null;
  addressAdditional: string | null;
  pix: string | null;
  hashAuth: string | null;
  haveQrcode: boolean | null;
  placeTypeId: number | null;
  placeType?: PlaceTypeInterface | null;
}

interface PointInterface {
  id?: number | null;
  placeId?: number | null;
  hash: string | null;
  description?: string | null;
  urlMenuTable?: string | null;
  tags?: Array<string> | null | unknown;
  qrcodeSize?: string | null;
  placeZoneId?: number | null;
}

const initialPlace: PlaceInterface = {
  id: null,
  name: null,
  urlImage: null,
  email: null,
  instagram: null,
  type: null,
  urlMenu: null,
  lat: null,
  lng: null,
  cnpj: null,
  legalName: null,
  bankName: null,
  bankAgency: null,
  bankAccount: null,
  address: null,
  addressNeighborhood: null,
  addressState: null,
  addressCity: null,
  addressCep: null,
  addressAdditional: null,
  pix: null,
  haveQrcode: false,
  placeTypeId: null,
  hashAuth: null,
}

interface QrcodeSizeInterface {
  id: number | null;
  name: string | null;
}

interface PlaceZoneInterface {
  id: number | null;
  name: string | null;
}

interface CamerasInterface {
  deviceId: string | null;
  label: string | null;
  kind: string | null;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#fff',
  boxShadow: 24,
  p: 4,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
};

const PlaceProfile = () => {
  const [hashList, setHashList] = useState<string[]>([]);
  const [width, setWidth] = useState(100);
  const [enableCapture, setEnableCapture] = useState(true);
  const [openReadQrcode, setOpenReadQrcode] = useState(false);
  const [cameras, setCameras ] = useState<CamerasInterface[]>();
  const [selectedCamera, setSelectedCamera ] = useState<CamerasInterface>();
  const [ point, setPoint ]= useState({placeZoneId: null, qrcodeSize: null})
  const  ref = useRef(null);

  const BoxQrCode = useCallback((node: any) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  //Place Context
  const { enqueueSnackbar } = useSnackbar();
  const { places, getPlace, patchPlaces } = usePlaces();
  const { placeZones, findPlaceZones } = usePlaceZones();
  const { qrcodeSizes, findQrcodeSizes } = useQrcodeSizes();
  const { createPoints } = usePoints();
  const [ place, setPlace ] = useState<PlaceInterface>(initialPlace);
  const { placeId } = useParams();

  const getPlaceInformation = () => {
    // if (!place.id) {
      getPlace(placeId);
    // }
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setPoint({
      ...point,
      [name]: value,
    });
  };

  const vibrate = () => {
    navigator.vibrate = navigator.vibrate;

      if (navigator.vibrate) {
        // vibration API supported
        navigator.vibrate(1000);
      }
  }

  useEffect(() => {
    (async () => {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      console.log('mediaDevices', JSON.stringify(mediaDevices));
      setCameras(mediaDevices.filter(device => device?.kind === 'videoinput'))
    })();
  }, []);

  useEffect(() => {
    getPlaceInformation();
    findPlaceZones({ query: { placeId }});
    findQrcodeSizes()
  }, [placeId]);

  useEffect(() => {
    console.log('useEffect setPlace')
    if(!place.id && places.length && places[0].id == placeId) {
      console.log('executando useEffect setPlace')
      setPlace(places[0] as PlaceInterface)
    }
  }, [places]);

  const validatePoint = (point: any) => {
    if (!point?.qrcodeSize) {
      enqueueSnackbar('Informe o tamanho do QrCode!', { variant: 'error' });
      return false;
    }

    if (!hashList.length) {
      enqueueSnackbar('Lista de QrCodes lidos está vazia. Leia ao menos um QrCode antes de salvar.', { variant: 'error' });
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (validatePoint(point)) {
      if (place?.id) {
        const newPoints: PointInterface[] = [];
        hashList.forEach(hash => {
          newPoints.push({
            ...point,
            placeId: Number(placeId),
            hash
          })
        });
        createPoints(newPoints)
          .then(() => {
            console.log('Deu certo');
            enqueueSnackbar('QrCodes salvos com sucesso!', {
              variant: 'success',
            });
            patchPlaces(placeId, { hashAuth: null })
          })
          .catch((error: any) => {
            console.log('Erro ao alterar', error);
            enqueueSnackbar('Erro ao alterar estabelecimento!', { variant: 'error' });
          });
      }
    }
  };
  
  const handleQrCodeRead = (result: string) => {
    console.log('code ', result)
    if (!enableCapture) {
      return false
    }
    setEnableCapture(false)
    const urlHash = result.split('?')[0];
    const newHash = result.split('?')[1];
    
    
    if (!urlHash || (!urlHash.includes('https://qrcoo.de/') && !urlHash.includes('https://qrcoud.com/'))) {
      enqueueSnackbar(`QrCode inválido!`, { variant: 'error' });
      return false
    }
    
    console.log('newHash', newHash)
    if (newHash) {
      if (!hashList.includes(newHash)) {
        const newHashList = [...hashList, newHash];
        setHashList(newHashList as string[]);
        enqueueSnackbar(`QrCode ${newHash} lido com sucesso!`, { variant: 'success' });
        vibrate()
      } else {
        enqueueSnackbar(`QrCode ${newHash} lido anteriormente!`, { variant: 'error' });
      }
      
    }

    setTimeout(() => {
      setEnableCapture(true)
    }, 1000);

  };

  console.log('place.placeType', place)
  return (
    <Box component="main" sx={{ flexGrow: 1, bgcolor: '#fff', height: '100%', p: 3 }}>
      <Typography variant='h5'>Vincular QrCode</Typography>
      {place.hashAuth && (
        <Grid
          sx={{ p: 1, borderRadius: 1 }}
          container
          columns={10}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box 
            sx={{ 
              bgcolor: 'rgba(255, 245, 245, 1)', 
              display: 'flex', 
              alignItems: 'flex-start', 
              flex: 1,
              p: 2,
              borderRadius: 1,
            }}
          >
            <Avatar sx={{ width: 50, height: 50, mr: 3 }} src={place?.urlImage}/>
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                <Typography variant="h5">
                  {place.name}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2"> 
                  {place.placeType?.name}
                </Typography>
              </Box>
            </Box>
          </Box>
          

          
          <Box ref={BoxQrCode} sx={{ width: '100%' }}>
            <FormControl fullWidth variant="outlined" sx={{ mt: 2, minWidth: 120 }}>

              <InputLabel id="demo-simple-select-outlined-label">
                Zona
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="placeZoneId"
                name="placeZoneId"
                fullWidth
                value={point.placeZoneId || ''}
                onChange={handleInputChange}
                input={
                  <OutlinedInput />
                }
                required
              >
                <MenuItem value="">Selecione</MenuItem>
                {placeZones.map(((zone: PlaceZoneInterface) => <MenuItem value={String(zone.id)}>{zone.name}</MenuItem>))}
                
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" sx={{ mt: 2, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Tamanho
                </InputLabel>

                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="qrcodeSize"
                  name="qrcodeSize"
                  fullWidth
                  value={point.qrcodeSize || ''}
                  onChange={handleInputChange}
                  label="Tamanho"
                  input={
                    <OutlinedInput />
                  }
                  required
                >
                  <MenuItem value="">Selecione</MenuItem>
                  {qrcodeSizes.map(((size: QrcodeSizeInterface) => <MenuItem value={String(size.name)}>{size.name}</MenuItem>))}
                  
                </Select>
              </FormControl>
            <Button fullWidth onClick={() => setOpenReadQrcode(true)} variant="contained" type="submit" sx={{ mt: 2 }}>
              <QrCode2Outlined style={{ marginRight: '1rem' }} />
              Abrir Leitor QrCode
            </Button>
            <p>{hashList.length} QrCodes lidos</p>
            <Button fullWidth onClick={handleSubmit} variant="contained" type="submit" sx={{ mt: 2 }}>
              Salvar
            </Button>
          </Box>
        </Grid>
      )}
      {!place.hashAuth && (
        <Box>Estabelecimento não está habilitado para cadastro de QrCode's</Box>
      )}
      <Modal
        open={openReadQrcode}
        onClose={() => console.log('fechou')}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <QrCodeReader 
            delay={1000} 
            width={width} 
            height={300} 
            onRead={handleQrCodeRead} 
            // videoConstraints={{ deviceId: selectedCamera ? selectedCamera?.deviceId as ConstrainDOMString : undefined}}
          /> */}
          <QrScanner
              onDecode={(result) => handleQrCodeRead(result)}
              onError={(error) => console.log(error?.message)}
              constraints={{ deviceId: selectedCamera ? selectedCamera?.deviceId as ConstrainDOMString : undefined}}
              scanDelay={1000}
              tracker={false}
          />
          <FormControl fullWidth variant="outlined" sx={{ mt: 2, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Cameras
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="placeZoneId"
              name="placeZoneId"
              fullWidth
              value={selectedCamera?.deviceId || ''}
              onChange={event => setSelectedCamera(cameras?.find(cam => String(cam?.deviceId) === event.target.value))}
              input={
                <OutlinedInput />
              }
              required
            >
              <MenuItem value="">Selecione</MenuItem>
              {cameras?.map(((cam: any) => <MenuItem value={cam?.deviceId}>{cam?.label}</MenuItem>))}
              
            </Select>
          </FormControl>
          {/* <Button fullWidth onClick={() => setEnableCapture(true)} variant="contained" type="submit" sx={{ mt: 2, mb: 4 }}>
            <QrCode2Outlined style={{ marginRight: '1rem' }} />
            { !enableCapture ? 'Capturar' : 'Aguardando captura' }
          </Button> */}
          <p>{hashList.length} QrCodes lidos</p>
          <Button fullWidth onClick={() => setOpenReadQrcode(false)} variant="contained" type="submit" sx={{ mt: 4 }}>
            Fechar
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default PlaceProfile;
