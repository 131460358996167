import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useAuth } from './contexts/useAuth';

//Pages
import SignIn from './pages/SignIn';
import PlaceProfile from './pages/PlaceProfile';


function Navigation() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const background = location.state && location.state.background;

  const { token, validateToken, isValidated } = useAuth();

  const checkToken = async () => {
    if (!token) {
      console.log('validando token ', token)
      await validateToken();
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  if (!isValidated) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  } else {
    return (
      <React.Fragment>
        {token ? (
          <React.Fragment>
            <Routes location={background || location}>
              <Route path="/" element={<p>URL incorreta</p>} />
              <Route path="/singin/:idPlace/:idUser" element={<SignIn />} />
              <Route
                path="/places/:placeId/qrcode"
                element={<PlaceProfile />}
              />
            </Routes>
          </React.Fragment>
        ) : (
          <Routes>
            <Route path="/singin/:idPlace/:idUser" element={<SignIn />} />
            <Route path="*" element={<SignIn />} />
          </Routes>
        )}
      </React.Fragment>
    );
  }
}

export default Navigation;
