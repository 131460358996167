import React from 'react';
import './App.css';
import Navigation from './Navigation';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { AuthProvider } from './contexts/useAuth';
import { UsersProvider } from './contexts/useUsers';
import { PlacesProvider } from './contexts/usePlaces';
import { PlaceZonesProvider } from './contexts/usePlaceZones';
import { PointsProvider } from './contexts/usePoints';
import { QrcodeSizesProvider } from './contexts/useQrcodeSizes';

function App() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={3000}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AuthProvider>
          <UsersProvider>
            <PlacesProvider>
              <PlaceZonesProvider>
                <PointsProvider>
                  <QrcodeSizesProvider>
                    <Router>
                      <Navigation />
                    </Router>
                  </QrcodeSizesProvider>
                </PointsProvider>
              </PlaceZonesProvider>
            </PlacesProvider>
          </UsersProvider>
        </AuthProvider>
      </LocalizationProvider>
    </SnackbarProvider>
  );
}

export default App;
