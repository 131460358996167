import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';

const PlaceZonesContext = createContext();
const initialPlaceZone = {
  id: null,
  name: null,
  placeId: null,
};

export function PlaceZonesProvider({ children }) {
  const [placeZones, setPlaceZones] = useState([]);
  const [editPlaceZone, setEditPlaceZone] = useState(initialPlaceZone);

  function getPlaceZone(placeZoneId) {
    services.placeZones.get(placeZoneId).then((response) => {
      console.log('getPlaceZone', response);
      setPlaceZones([response]);
    });
  }

  function getEditPlaceZone(placeZoneId) {
    services.placeZones.get(placeZoneId).then((response) => {
      console.log('getPlaceZone', response);
      setEditPlaceZone(response);
    });
  }

  function findPlaceZones(query = {}) {
    services.placeZones.find(query).then((response) => {
      console.log('findPlaceZones', response);
      setPlaceZones(response.data);
    });
  }

  async function createPlaceZones(placeZone) {
    return services.placeZones.create(placeZone).then(() => {
      findPlaceZones({ query: { placeId: placeZone.placeId}});
    });
  }

  async function patchPlaceZones(id, placeZone) {
    return services.placeZones.patch(id, placeZone).then(() => {
      setEditPlaceZone(initialPlaceZone);
      findPlaceZones({ query: { placeId: placeZone.placeId}});
    });
  }

  async function removePlaceZones(placeZoneId) {
    return services.placeZones.remove(placeZoneId).then(() => {
      findPlaceZones();
    });
  }
  return (
    <PlaceZonesContext.Provider
      value={{
        placeZones,
        editPlaceZone,
        setEditPlaceZone,
        getEditPlaceZone,
        findPlaceZones,
        createPlaceZones,
        patchPlaceZones,
        getPlaceZone,
        removePlaceZones,
      }}
    >
      {children}
    </PlaceZonesContext.Provider>
  );
}

export function usePlaceZones() {
  const context = useContext(PlaceZonesContext);

  return context;
}
