/*eslint-disable no-restricted-globals*/

import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { decode } from 'base-64'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../contexts/useAuth';
import { usePlaces } from '../contexts/usePlaces';
import { Link, useNavigate, useParams } from 'react-router-dom';
import '../styles/SignIn.css';

const theme = createTheme();

const buttonPrimary = {
  mt: 3,
  mb: 2,
  color: '#FFFFFF',
  backgroundColor: '#FF4C51',
  '&:hover': {
    backgroundColor: '#df4247',
  },
};

function SignIn() {
  const { validateLoginToken, isValidated } = useAuth();
  const { places, findPlaces } = usePlaces();
  const navigate = useNavigate();
  const { idPlace, idUser } = useParams();
  const [ loading, setLoading] = useState(true);

  const checkToken = async () => {
    console.log('Verificando ', idUser)
    if (idUser) {
      const tokenDecoded = decode(idUser);
      validateLoginToken(tokenDecoded).then(() => {
        findPlaces({ query: { hashAuth: idPlace }});
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('useEffect places', places)
    if(places[0]) {
      console.log('Navigate to', places[0])
      navigate(`/places/${places[0].id}/qrcode`);
    }
  }, [places]);

  useEffect(() => {
    checkToken();
  }, [idUser]);

  console.log(' return places ', places)
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" className="signInPanel">
        <CssBaseline />
        <Box className="signInForm">
          {loading && (
            <Typography>Aguarde</Typography>
          )}
          {(!loading && (!idPlace || !idUser)) && (
            <Typography>Acesso Negado</Typography>
          )}
          {/* {(!loading && isValidated) && (
            <Typography>Acesso Negado</Typography>
          )} */}
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default SignIn;
