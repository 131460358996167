import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';

const PointsContext = createContext();
const initialPoint = {
  id: null,
  hash: null,
  urlMenuTable: null,
  tag: null,
  qrcodeSize: null,
  placeId: null,
};

export function PointsProvider({ children }) {
  const [points, setPoints] = useState([]);
  const [editPoint, setEditPoint] = useState(initialPoint);
  const [hashPointVerify, setHashPointVerify] = useState([]);

  function getPoint(pointId) {
    services.points.get(pointId).then((response) => {
      console.log('getPoint', response);
      setPoints([response]);
    });
  }

  function getEditPoint(pointId) {
    services.points.get(pointId).then((response) => {
      console.log('getPoint', response);
      setEditPoint(response);
    });
  }

  function findPoints(query = {}) {
    services.points.find(query).then((response) => {
      console.log('findPoints', response);
      setPoints(response.data);
    });
  }

  function findHashPoints(hash) {
    services.points.find({query: { hash }}).then((response) => {
      console.log('findPoints', response);
      setPoints(response.data);
    });
  }


  async function createPoints(point) {
    return services.points.create(point).then(() => {
      findPoints({ query: { placeId: point.placeId}});
    });
  }

  async function patchPoints(id, point) {
    return services.points.patch(id, point).then(() => {
      setEditPoint(initialPoint);
      findPoints({ query: { placeId: point.placeId}});
    });
  }

  async function removePoints(pointId) {
    return services.points.remove(pointId).then(() => {
      findPoints();
    });
  }
  return (
    <PointsContext.Provider
      value={{
        points,
        editPoint,
        hashPointVerify,
        findHashPoints,
        setEditPoint,
        getEditPoint,
        findPoints,
        createPoints,
        patchPoints,
        getPoint,
        removePoints,
      }}
    >
      {children}
    </PointsContext.Provider>
  );
}

export function usePoints() {
  const context = useContext(PointsContext);

  return context;
}
